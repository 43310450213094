// istanbul ignore file
import * as React from 'react';
import { Box, BoxProps } from '@mui/system';
import { GlobalStyles, css } from '@mui/material';

import { useAppTheme } from '../../../../../theme';
import { DateTimestampMs } from '../../../../../types/stein';
import { useTimelineContext } from './TimelineProvider';
import { isWithinInterval, minutesToMilliseconds, addMilliseconds, isEqual } from 'date-fns';
import { useMultiVideoState } from '../../../../VideoPlayerMulti/VideoPlayerMulti';
import { useTimer } from '../../../../../hooks/use-timeout';
import { IconButtonSimple } from '../../../../material/IconButtonSimple';
import { AppIconChevronRight, AppIconChevronLeft } from '../../../../AppIcons';
import { Stack } from '@mui/material';

export function TimelineWrapper({ children, sx = {}, ...boxProps }: BoxProps): React.ReactElement {
    const timelineRef = React.useRef<HTMLDivElement>();
    const { zoom, startTime, getPositionLeft, visibleDurationMs, durationMs, controller } = useTimelineContext();

    const { playbackTime, playbackState } = useMultiVideoState(controller);
    const theme = useAppTheme();
    const [recentlyScrolled] = useTimer();

    const [windowStart, _setWindowStart] = React.useState<DateTimestampMs>(startTime);
    const isVisible = React.useCallback(
        // istanbul ignore next
        function isVisibleImpl(t: DateTimestampMs, bufferMs: number): boolean {
            return isWithinInterval(new Date(t), {
                start: addMilliseconds(new Date(windowStart), bufferMs),
                end: addMilliseconds(new Date(windowStart), visibleDurationMs - bufferMs),
            });
        },
        [windowStart, visibleDurationMs],
    );

    const endTime = startTime + durationMs;
    const windowEnd = windowStart + visibleDurationMs;

    const setWindowStart = React.useCallback(
        (tOrF: React.SetStateAction<DateTimestampMs>) => {
            _setWindowStart((prev) => {
                const time = typeof tOrF === 'function' ? tOrF(prev) : tOrF;
                const max = Math.max(startTime, time);
                const clamped = Math.min(max, startTime + durationMs - visibleDurationMs) as DateTimestampMs;
                return clamped;
            });
        },
        [_setWindowStart, startTime, durationMs, visibleDurationMs, playbackState],
    );

    React.useEffect(() => {
        setWindowStart((s) => s);
    }, [zoom]);

    React.useEffect(() => {
        setWindowStart(startTime);
    }, [startTime]);

    React.useEffect(() => {
        if (
            !recentlyScrolled &&
            playbackState === 'playing' &&
            playbackTime &&
            !isVisible(playbackTime, minutesToMilliseconds(15))
        ) {
            setWindowStart((playbackTime - minutesToMilliseconds(15)) as DateTimestampMs);
        }
    }, [isVisible, playbackTime, playbackState, recentlyScrolled]);

    // React.useEffect(() => {
    //     const ele = timelineRef?.current;
    //     function handleEvent(e: WheelEvent): boolean {
    //         e.preventDefault();
    //         e.stopPropagation();
    //         const deltaTime = -(e.deltaX || e.deltaY) * (visibleDurationMs / 1000);
    //         setWindowStart((w) => (w + deltaTime) as DateTimestampMs);
    //         if (playbackState === 'playing') {
    //             startScrollTimer(2000);
    //         }
    //         return false;
    //     }
    //     if (ele) {
    //         ele.addEventListener('wheel', handleEvent, { passive: false, capture: true });
    //         return () => ele.removeEventListener('wheel', handleEvent);
    //     }
    // }, [timelineRef?.current, startTime, durationMs, visibleDurationMs, playbackState]);

    return (
        <Stack direction={'row'} height={'100%'}>
            <IconButtonSimple
                sx={{ padding: 0.5 }}
                onClick={() =>
                    setWindowStart(Math.max(startTime, windowStart - visibleDurationMs / 2) as DateTimestampMs)
                }
                disabled={isEqual(windowStart, startTime)}
            >
                <AppIconChevronLeft />
            </IconButtonSimple>
            <Box
                ref={timelineRef}
                {...boxProps}
                sx={{
                    ...sx,
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <GlobalStyles
                    styles={css`
                        html,
                        body {
                            overscroll-behavior-x: none;
                        }
                    `}
                />
                <Box
                    component={'div'}
                    sx={{
                        width: `${Math.round(zoom * 100)}%`,
                        marginLeft: `-${getPositionLeft(windowStart)}`,
                        height: '100%',
                        position: 'relative',
                        overflow: 'hidden',
                        transition: theme.transitions.create('margin-left', {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.short,
                        }),
                    }}
                >
                    {children}
                </Box>
            </Box>
            <IconButtonSimple
                sx={{ padding: 0.5 }}
                onClick={() =>
                    setWindowStart(
                        Math.min(endTime - visibleDurationMs, windowStart + visibleDurationMs / 2) as DateTimestampMs,
                    )
                }
                disabled={isEqual(windowEnd, endTime)}
            >
                <AppIconChevronRight />
            </IconButtonSimple>
        </Stack>
    );
}
